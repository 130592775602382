import React, {FC, useEffect} from 'react';
import {css} from '@emotion/core';
import {Flex} from 'theme-ui';
import {PageLoadEvent} from '@s1/extensions';
import {Main} from '../../../common/templates/RootLayoutComponents';
import BackgroundImage from '../components/new-tab/BackgroundImage';
import BottomMenu from '../components/new-tab/BottomMenu';
import SpeedDial from '../components/new-tab/SpeedDial';
import PrivateSearchBar from '../components/new-tab/PrivateSearchBar';
import {useHistory} from '../hooks/useHistory';
import Redirect from '../../mapquest/util/redirect';

const NewTab: FC<{}> = () => {
    const history = useHistory();

    // any other props that come into the component
    useEffect(() => {
        const pageLoadEvent = PageLoadEvent();
        if (pageLoadEvent !== null) {
            window.dispatchEvent(pageLoadEvent);
        }
    }, []);

    const onSubmit = ({query}: {query: string}) => {
        Redirect(`https://ext.mapquest.com/serp?q=${query}&segment=mq.extension.yhs01`);
    };

    return (
        <Main>
            <BackgroundImage>
                <Flex
                    css={css`
                        height: 100vh;
                        width: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Flex
                        css={css`
                            flex-direction: column;
                            width: 100%;
                            max-width: 677px;
                        `}
                    >
                        <PrivateSearchBar placeholder="" onSubmit={onSubmit} />
                        <SpeedDial title="Most Visited" items={history} />
                        <SpeedDial title="Popular" items={[]} />
                        <BottomMenu title="Mapquest Directions" collapsed />
                    </Flex>
                </Flex>
            </BackgroundImage>
        </Main>
    );
};

export default NewTab;
