import * as yup from 'yup';

const config = {
    key: 'Cmjtd%7Cluur2108n1,7w%3Do5-gz8a',
    apiUrl: 'https://temp.mapquestapi.com/search/v3/prediction'
};

const PropertySchema = yup
    .object({
        displayString: yup.string().defined()
    })
    .defined();

const ResponseSchema = yup
    .object({
        results: yup.array().of(PropertySchema).ensure().defined()
    })
    .defined();

type Response = yup.InferType<typeof ResponseSchema>;
type PlaceProperties = yup.InferType<typeof PropertySchema>;

const buildSearchUrl = (query: string) => {
    const collections = ['address', 'adminArea', 'airport', 'category', 'franchise', 'poi'];
    const apiUrl = new URL(`${config.apiUrl}/?key=${config.key}`);
    apiUrl.searchParams.append('q', query);
    apiUrl.searchParams.append('limit', '7');
    apiUrl.searchParams.append('countryCode', 'US');
    apiUrl.searchParams.append('feedback', 'false');
    apiUrl.searchParams.append(
        'collection',
        collections.reduce((prev, cur) => {
            return `${prev},${cur}`;
        })
    );
    return apiUrl.toString();
};

export const fetchResults = async (query: string) => {
    if (query.length < 2 || query.length > 100) {
        return [];
    }
    const url = buildSearchUrl(query);
    const resp = await fetch(url);
    const json = await resp.json();
    let validatedResults: Response = {results: []};

    try {
        validatedResults = await ResponseSchema.validate(json, {
            stripUnknown: true,
            strict: false // cast fields to type in schema
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
    return validatedResults.results.map((result) => result.displayString);
};
