import React, {FC, useCallback, useState, useEffect} from 'react';
import {Flex, Text} from 'theme-ui';
import {css} from '@emotion/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp} from '@fortawesome/pro-solid-svg-icons';
import {animated, useSpring, config} from 'react-spring';
import MapquestIcon from '../../assets/svg/mapquest-icon-white.svg';
import DirectionsSearch from './DirectionSearch';
import RoundIcon from './RoundIcon';

type BottomMenuProps = {
    title: string;
    collapsed?: boolean;
};

const DirectionsPanel: FC = () => {
    return (
        <Flex
            css={css`
                width: 100%;
                flex-direction: column;
                background-color: #f9f9fc;
                height: 100%;
                padding: 32px 32px 0 32px;
                border-radius: 12px 12px 0 0;
            `}
        >
            <DirectionsSearch />
        </Flex>
    );
};

const BottomMenu: FC<BottomMenuProps> = ({title, collapsed = true}) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    const containerHeight = 394; // px
    const overHang = 100;
    const headerHeight = 65; // px
    const props = useSpring({
        transform: `translateY(${isCollapsed ? containerHeight - headerHeight : 0}px)`,
        config: config.stiff
    });

    useEffect(() => {
        setIsCollapsed(collapsed);
    }, [collapsed]);

    const toggleCollapsed = useCallback(() => {
        setIsCollapsed(!isCollapsed);
    }, [isCollapsed]);

    return (
        <animated.div
            style={props}
            css={css`
                width: 677px;
                height: ${containerHeight + overHang}px;
                border-radius: 26px 26px 0 0;
                background-color: #ffffff;
                padding: 17px 34px 0 34px;
                position: fixed;
                bottom: -${overHang}px;
                z-index: 1;
                color: #000;
            `}
        >
            <Flex
                css={css`
                    align-items: center;
                    padding: 0 0 27px 0;
                `}
                onClick={toggleCollapsed}
            >
                <RoundIcon color={isCollapsed ? '#007aff' : '#acb3bb'} icon={<MapquestIcon height={14} />} />
                <Text
                    css={css`
                        text-transform: uppercase;
                        font-size: 14px;
                    `}
                >
                    {title}
                </Text>
                <Flex
                    css={css`
                        flex: 1 1 0;
                    `}
                />
                <FontAwesomeIcon icon={faAngleUp} rotation={isCollapsed ? undefined : 180} />
            </Flex>
            <Flex
                css={css`
                    height: 309px;
                    flex-direction: row;
                    align-items: flex-end;
                `}
            >
                <DirectionsPanel />
            </Flex>
        </animated.div>
    );
};

export default BottomMenu;
