import React, {FC, ReactNode} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';

type AttributionUrls = {
    site: string;
    user: string;
};

type Attribution = {
    name: string;
    urls: AttributionUrls;
};

type UnsplashData = {
    imgUrl: string;
    attribution: Attribution;
};

export type Ref = HTMLElement;

type BackgroundImageProps = {
    children?: ReactNode;
};

const useUnsplashImage = () => {
    const unsplashResponse = useStaticQuery(graphql`
        query UnsplashQuery {
            unsplashPhoto {
                id
                alt_description
                urls {
                    raw
                    full
                    regular
                    small
                    thumb
                }
                user {
                    name
                    links {
                        html
                    }
                }
            }
        }
    `);

    const data: UnsplashData = {
        attribution: {
            name: unsplashResponse.unsplashPhoto.user.name,
            urls: {
                site: 'https://unsplash.com/',
                user: unsplashResponse.unsplashPhoto.user.links.html
            }
        },
        imgUrl: unsplashResponse.unsplashPhoto.urls.regular
    };

    return data;
};

const BackgroundImage: FC<BackgroundImageProps> = ({children}) => {
    const data = useUnsplashImage();

    const styles = css`
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        position: relative;
        overflow: hidden;
        display: flex;
        ${data?.imgUrl ? `background-image: url("${data.imgUrl}");` : ''}
        ${data?.imgUrl ? `background-size: cover;` : ''}
        ${data?.imgUrl ? `object-fit: cover;` : ''}
    `;

    return (
        <div css={styles}>
            {children}
            {data && data.attribution && (
                <figcaption>
                    <UnsplashAttribution name={data.attribution.name} urls={data.attribution.urls} />
                </figcaption>
            )}
        </div>
    );
};

const UnsplashAttribution: FC<Attribution> = ({name, urls}) => {
    return (
        <div
            css={css`
                position: absolute;
                bottom: 1rem;
                left: 1rem;
                color: white;

                a {
                    color: white;
                    font-weight: bold;
                    font-style: italic;
                }
            `}
        >
            Photo by{' '}
            <a href={urls.user} target="_blank" rel="noreferrer">
                {name}
            </a>{' '}
            on{' '}
            <a href={urls.site} target="_blank" rel="noreferrer">
                Unsplash
            </a>
        </div>
    );
};

export default BackgroundImage;
