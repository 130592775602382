import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {Flex, IconButton, Text, Input, Button, Divider} from 'theme-ui';
import scrollbarStyle from 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {css} from '@emotion/core';

import RoundIcon from './RoundIcon';

import BagIcon from '../../assets/svg/new-tab/bag.svg';
import CoffeeCupIcon from '../../assets/svg/new-tab/coffee-cup.svg';
import GasIcon from '../../assets/svg/new-tab/gas.svg';
import HotelIcon from '../../assets/svg/new-tab/hotel.svg';
import PizzaIcon from '../../assets/svg/new-tab/pizza.svg';
import ReverseIcon from '../../assets/svg/new-tab/reverse.svg';
import WalkingIcon from '../../assets/svg/new-tab/walking.svg';
import DoctorIcon from '../../assets/svg/new-tab/doctor.svg';
import MapquestIcon from '../../assets/svg/mapquest-icon-white.svg';
import Pin from '../../assets/svg/new-tab/pin.svg';
import {useDebounce} from '../../../../common/hooks/useDebounce';
import {fetchResults} from './mapquestApi';

type DirectionsCategoryProps = {
    icon: React.ReactElement;
    label: string;
    category: string;
    onClick: (category: string) => void;
};

type SuggestionProps = {
    address: string;
    onClick: (address: string) => void;
};

const Suggestion: FC<SuggestionProps> = ({address, onClick}) => (
    <Flex
        css={css`
            width: 100%;
            flex-direction: row;
            align-items: center;
        `}
        onClick={() => onClick(address)}
    >
        <Pin
            css={css`
                margin-right: 14px;
            `}
        />
        <Flex
            css={css`
                flex-direction: column;
            `}
        >
            <Text
                css={css`
                    font-size: 10px;
                `}
            >
                {address}
            </Text>
            <Text
                css={css`
                    font-size: 10px;
                    opacity: 0.4;
                `}
            >
                Address
            </Text>
        </Flex>
    </Flex>
);

type SearchSuggestionsProps = {
    title: string;
    query: string;
    onClick: (adresss: string) => void;
};

const SearchSuggestions: FC<SearchSuggestionsProps> = ({title, query, onClick}) => {
    const [addresses, setAddresses] = useState<string[]>([]);
    const debounceQuery = useDebounce<string>(query, 600);

    useEffect(() => {
        fetchResults(debounceQuery).then((results) => {
            setAddresses(results);
        });
    }, [debounceQuery]);

    return addresses.length === 0 ? null : (
        <Flex
            css={css`
                flex-direction: column;
                width: 100%;
                background-color: #fff;
                border-radius: 6px;
                padding: 14px;
            `}
        >
            <Text
                css={css`
                    font-size: 12px;
                    margin-bottom: 14px;
                `}
            >
                {title}
            </Text>
            <Flex
                css={css`
                    flex-direction: column;
                    width: 100%;
                `}
            >
                {addresses.map((address) => (
                    <Flex
                        css={css`
                            width: 100%;
                            flex-direction: column;
                        `}
                    >
                        <Suggestion key={address} address={address} onClick={onClick} />
                        <Divider
                            css={css`
                                width: 100%;
                                color: #f9f9fc;
                            `}
                        />
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

const DirectionsCategory: FC<DirectionsCategoryProps> = ({icon, label, category, onClick}) => {
    return (
        <Flex
            css={css`
                flex-direction: column;
                width: 100%;
                align-items: center;
                font-size: 12px;
            `}
        >
            <IconButton
                type="button"
                css={css`
                    outline: none;
                `}
                onClick={() => {
                    onClick(category);
                }}
            >
                {icon}
            </IconButton>
            <Text>{label}</Text>
        </Flex>
    );
};

type DirectionsInputProps = {
    query: string;
    name: string;
    placeholder: string;
    onChange: (inputState: {[key: string]: string}) => void;
    onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
};

const DirectionsInput: FC<DirectionsInputProps> = ({query, name, placeholder, onChange, onFocus, onBlur}) => {
    const [directionsQuery, setDirectionsQuery] = useState('');

    useEffect(() => {
        setDirectionsQuery(query);
    }, [query]);

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const {value} = evt.target;
        onChange({[name]: value});
        setDirectionsQuery(value);
    };

    return (
        <Input
            css={css`
                border-radius: 10px;
                background-color: #ffffff;
                flex: 1 1 0;
                border: unset;
                height: 24px;
                padding: 10px;
                font-size: 12px;
                max-width: 164px;
                &:focus {
                    border: solid 1px #007aff;
                    outline: none;
                }
            `}
            value={directionsQuery}
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={handleChange}
        />
    );
};

const DirectionsSearch: FC<{}> = () => {
    const [directionState, setDirectionState] = useState({
        category: '',
        from: '',
        to: ''
    });

    const [activeSearchBar, setActiveSearchBar] = useState<'from' | 'to'>('from');

    const reverse = () => {
        const {from, to} = directionState;
        setDirectionState({...directionState, from: to, to: from});
    };

    const handleInputChange = (inputState: {[key: string]: string}) => {
        setDirectionState({...directionState, ...inputState});
    };

    const handleCategoryChange = (category: string) => {
        setDirectionState({...directionState, category});
    };

    return (
        <form
            css={css`
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            `}
            onSubmit={(e) => {
                // Go to mapquest.com
                e.preventDefault();
            }}
        >
            <Flex
                css={css`
                    width: 100%;
                    padding-bottom: 23px;
                `}
            >
                <DirectionsCategory
                    onClick={handleCategoryChange}
                    category="hotels"
                    icon={<HotelIcon />}
                    label="Hotels"
                />
                <DirectionsCategory onClick={handleCategoryChange} category="food" icon={<PizzaIcon />} label="Food" />
                <DirectionsCategory
                    onClick={handleCategoryChange}
                    category="gas"
                    icon={<GasIcon />}
                    label="Gas Prices"
                />
                <DirectionsCategory
                    onClick={handleCategoryChange}
                    category="coffee"
                    icon={<CoffeeCupIcon />}
                    label="Coffee"
                />
                <DirectionsCategory
                    onClick={handleCategoryChange}
                    category="shopping"
                    icon={<BagIcon />}
                    label="Shopping"
                />
                <DirectionsCategory
                    onClick={handleCategoryChange}
                    category="doctors"
                    icon={<DoctorIcon />}
                    label="Doctors"
                />
            </Flex>
            <Flex
                css={css`
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                `}
            >
                <RoundIcon color="#007aff" icon={<MapquestIcon height={14} />} />
                <RoundIcon
                    color="#fff"
                    style={css`
                        box-shadow: 0 2px 11px 0 rgba(27, 80, 137, 0.13);
                    `}
                    icon={<WalkingIcon />}
                />
                <DirectionsInput
                    onChange={handleInputChange}
                    query={directionState.from}
                    name="from"
                    placeholder="From"
                    onFocus={() => {
                        setActiveSearchBar('from');
                    }}
                />
                <ReverseIcon onClick={reverse} />
                <DirectionsInput
                    onChange={handleInputChange}
                    query={directionState.to}
                    name="to"
                    placeholder="To"
                    onFocus={() => {
                        setActiveSearchBar('to');
                    }}
                />
                <Button
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ff;
                        background-color: #007aff;
                        border-radius: 15px;
                        flex: 1 1 0;
                        max-width: 78px;
                        height: 26px;
                        font-size: 12px;
                        outline: none;
                    `}
                >
                    <Text>GO</Text>
                </Button>
            </Flex>
            <PerfectScrollbar
                css={css`
                    width: 100%;
                    max-width: 350px;
                    ${scrollbarStyle}
                `}
            >
                {activeSearchBar === 'from' ? (
                    <SearchSuggestions
                        title="From Location"
                        query={directionState.from}
                        onClick={(address) => {
                            setDirectionState({...directionState, from: address});
                        }}
                    />
                ) : (
                    <SearchSuggestions
                        title="To Location"
                        query={directionState.to}
                        onClick={(address) => {
                            setDirectionState({...directionState, to: address});
                        }}
                    />
                )}
            </PerfectScrollbar>
        </form>
    );
};

export default DirectionsSearch;
