/** @jsx jsx */

import {jsx} from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {FC, PropsWithChildren} from 'react';
import {SystemStyleObject} from '@styled-system/css';

const HeaderCSSProps: SystemStyleObject = {
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    variant: 'layout.header'
};
const FooterCSSProps: SystemStyleObject = {
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    variant: 'layout.footer'
};

const MainCSSProps: SystemStyleObject = {
    height: '100%',
    flex: '1 1 auto',
    variant: 'layout.main'
};

export const Header: FC<PropsWithChildren<{}>> = ({children}) => <header sx={HeaderCSSProps}>{children}</header>;
export const Footer: FC<PropsWithChildren<{}>> = ({children}) => <footer sx={FooterCSSProps}>{children}</footer>;
export const Main: FC<PropsWithChildren<{}>> = ({children}) => <main sx={MainCSSProps}>{children}</main>;
