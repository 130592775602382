import React, {FC, useState} from 'react';
import {Flex, Text, IconButton, Box} from 'theme-ui';
import {css} from '@emotion/core';

type IconProps = {
    icon: string;
    onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string;
};

const getFavicon = (url: string) => {
    return `https://www.google.com/s2/favicons?sz=64&domain_url=${encodeURI(url)}`;
};

const Icon: FC<IconProps> = ({icon, onClick, label}) => {
    const [hover, setHover] = useState(false);

    return (
        <Flex
            css={css`
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 20px;
            `}
            onMouseEnter={() => {
                if (!hover) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => {
                if (hover) {
                    setHover(false);
                }
            }}
        >
            <IconButton
                css={css`
                    width: 72px;
                    height: 72px;
                    opacity: 1;
                    border-radius: 22px;
                    background-color: #ffffff;
                `}
                onClick={onClick}
            >
                <img
                    css={css`
                        width: 45px;
                        height: 45px;
                    `}
                    src={icon}
                    alt={label}
                />
            </IconButton>
            <Text
                css={css`
                    margin-top: 10px;
                    color: #fff;
                    font-size: 12px;
                `}
            >
                {label}
            </Text>
            <Box
                css={css`
                    display: ${hover ? 'box' : 'none'};
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.24;
                    border-radius: 10px;
                    background-color: #ffffff;
                `}
            />
        </Flex>
    );
};

type SpeedDialItem = {
    url: string;
    label: string;
};

type SpeedDialProps = {items: SpeedDialItem[]; title: string};

const SpeedDial: FC<SpeedDialProps> = ({items, title}) => {
    return (
        <Flex
            css={css`
                flex-direction: column;
            `}
        >
            <Flex
                css={css`
                    width: 114px;
                    height: 35px;
                    border-radius: 17.5px;
                    background-color: rgba(0, 0, 0, 0.13);
                    justify-content: center;
                    align-items: center;
                    margin: 28px 0;
                `}
            >
                <Text
                    css={css`
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #fff;
                    `}
                >
                    {title}
                </Text>
            </Flex>
            <Flex
                css={css`
                    width: 100%;
                    justify-content: space-between;
                `}
            >
                {items.map(({url, label}) => (
                    <Icon
                        label={label}
                        onClick={() => {
                            window.location.href = url;
                        }}
                        icon={getFavicon(url)}
                    />
                ))}
            </Flex>
        </Flex>
    );
};

export default SpeedDial;
