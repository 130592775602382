import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {BrowserHistoryData} from '@s1/extensions';
import {useLogging} from '../../../common/hooks/useLogging';

const HistorySchema = yup
    .array()
    .of(
        yup
            .object({
                url: yup.string().defined(),
                label: yup.string().defined()
            })
            .defined()
    )
    .defined();

type History = yup.InferType<typeof HistorySchema>;

export const useHistory = () => {
    const [history, setHistory] = useState<History>([]);

    const {ExtensionsErrorEvent} = useLogging();

    useEffect(() => {
        const historyHandler = (evt: CustomEvent<BrowserHistoryData>) => {
            HistorySchema.validate(evt?.detail)
                .then((data) => {
                    setHistory(data);
                })
                .catch((e) => {
                    ExtensionsErrorEvent({type: 'useHistory', message: e.toString()});
                });
        };
        window.addEventListener('browserHistory', historyHandler as EventListener);
        return () => window.removeEventListener('browserHistory', historyHandler as EventListener);
    });

    return history;
};
